$fs-supplementary-text: 12px;/*辅助文字*/
$fs-body-small: 13px;/*正文-小*/
$fs-body: 14px;/*正文*/
$fs-title-small: 16px;/*小标题*/
$fs-title: 18px;/*标题*/
$fs-main-title: 20px;/*主标题*/
$fs-main-normal: 22px;/*大一号字体*/
$fs-main-big: 24px;/*大号字体*/

$fc-primary: #222;/*主要文字*/
$fc-regular: #606266;/*常规文字*/
$fc-secondary: #8f8f8f;/*次要文字*/
$fc-placeholder: #c0c4cc;/*占位文字*/

$fc-theme: #0aa665;/*主题色*/
$fc-theme-deep: #ffffff;/*主题色（背景色）*/
$fc-theme-menu-hover: rgb(240,249,255);/*主题色（左侧导航栏hover背景色）*/
$fc-success: #0aa665;/*成功*/
$fc-success-deep: #e1f3d8;/*成功（深）*/
$fc-success-shallow: #f0f9eb;/*成功（浅）*/
$fc-danger: #FF4D4F;/*错误*/
$fc-danger-deep: #fde1e2;/*错误（深）*/
$fc-danger-shallow: #feeff0;/*错误（浅）*/
$fc-warning: #FAAD14;/*警告*/
$fc-warning-deep: #faecd8;/*警告（深）*/
$fc-warning-shallow: #fdf5ec;/*警告（浅）*/
$fc-info: #409EFF;/*信息*/
$fc-info-deep: #d8ecff;/*信息（深）*/
$fc-info-shallow: #ecf5ff;/*信息（浅）*/

$fc-bg-shallow: #efefef;/*背景颜色(灰深)*/
$fc-bg-deep: #f5f5f5;/*背景颜色（灰浅）*/

$lg-logo: './../assets/img/login/lg-logo.png'; /*左侧菜单栏上方的logo*/
$lg-logo-hide:'./../assets/img/login/lg-logo-hide.png';/*左侧菜单栏上方的logo*/
