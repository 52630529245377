$publicPath:'/tantan-door-admin';


























































































































































@import "../assets/css/global-variable.scss";
.img-upload {
  width: 100px;
  height: 100px;
  margin-left: 20px;
}
.g-role-add-box {
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.m-tt {
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.m-tt-t {
  margin-left: 100px;
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.lcline {
  margin-left: 100px;
}
.u-tag {
  margin-right: 10px;
  cursor: pointer;
}
.m-select {
  border: 1px solid rgb(185, 180, 180);
  border-radius: 4px;
  padding: 0 6px;
  height: 300px;
  .u-select-hd {
    border-bottom: 1px solid rgb(185, 180, 180);
    .u-select-hd-icon {
      float: right;
      margin-top: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .u-select-bt {
    overflow: auto;
    height: 260px;
  }
}
.s-h4 {
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload {
  border: 1px dashed #409eff;
}
.avatar-uploader-icon {
  border: 1px solid #ddd;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
